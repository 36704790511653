import * as API from "@/services/API";

const baseUrl = '/v1/reporting/conversations';


export default {
    getConversation(id) {
        return API.apiClient.get(`${baseUrl}/${id}`);
    },
    getConversations(filters) {
        return API.apiClient.get(baseUrl, {params: filters});
    },
    getConversationRecordings(id) {
        return API.apiClient.get(`${baseUrl}/${id}/recordings`);
    },
    getConversationData(id) {
        return API.apiClient.get(`${baseUrl}/${id}/data`);
    },
    getConversationLog(id) {
        return API.apiClient.get(`${baseUrl}/${id}/log`);
    },
    getConversationActions(id) {
        return API.apiClient.get(`${baseUrl}/${id}/actions`);
    },
    getConversationTranscript(filters) {
        return API.apiClient.get(`${baseUrl}/${filters.id}/transcript`, {params: { export: filters.export}});
    },  
    requestOutboundCall(payload){
        return API.apiClient.post(`/v1/conversations/instance/${payload.instanceId}/call/${payload.phone}`, {
            ClientId: payload.ClientId
        });
    },
    getQuote(conversationId) {
        return API.apiClient.get(`${baseUrl}/${conversationId}/quotes`);
    },
    getUsage(id) {
        return API.apiClient.get(`${baseUrl}/${id}/usage-summary`);
    }
}